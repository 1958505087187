<template>
  <div>
    <b-card :title="$t('Pending payouts')" v-if="items.length > 0">
      <b-overlay :show="!loaded" rounded="sm">
        <b-table
            ref="refaccountCampaignListTable"
            class="position-relative"
            responsive
            primary-key="id"
            :items="items"
            :per-page="perPage"
            :current-page="currentPage"
            :fields="tableColumns"
            :sort-by.sync="sortBy"
            :filter="filter"
            @filtered="onFiltered"
            show-empty
            :empty-text="$t('No matching records found')"
        >
          <template #head()="data">
            {{ $t(data.label) }}
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-button :to="{ name: 'admin-admin-users-edit', params: { userid: data.item.hashid } }"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      type="submit"
                      size="sm"
            > {{ $t('View order') }}
            </b-button>
          </template>
        </b-table>
      </b-overlay>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">
              {{ $t('Showing') }}
              <span v-if="items.length === 0"> {{ items.length }}</span>
              <span v-else-if="items.length > 0"> {{ perPage * (currentPage - 1) + (perPage ? 1 : 0) }}</span>
              {{ $t('to') }}
              <span v-if="items.length < perPage - 1"> {{ items.length }}</span>
              <span v-else-if="perPage * (currentPage) > items.length"> {{ items.length }}</span>
              <span v-else> {{ perPage * (currentPage - 1) + perPage }}</span>
              {{ $t('of') }} {{ items.length }} {{ $t('entries') }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
                v-model="currentPage"
                :total-rows="items.length"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardText,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
  BPagination,
  BRow,
  BTable
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import moment from 'moment'
import TableHeader from '@/views/components/TableHeader'
import TableFooter from '@/views/components/TableFooter'
export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BOverlay,
    BAlert,
    BCardText,
    BImg,
    vSelect
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loaded: false,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      userData: JSON.parse(localStorage.getItem('userData')),
      tableColumns: [
        {
          key: 'status_text',
          sortable: true,
          label: 'Status'
        },
        {
          key: 'created_at',
          sortable: true,
          label: 'Date',
          formatter: (value) => {
            return moment(String(value))
                .format('DD.MM.YYYY HH:mm')
          }
        },
        {
          key: 'credits',
          sortable: true,
          label: '# Credits'
        },
        {
          key: 'last_four',
          sortable: true,
          label: 'IBAN',
          formatter: (value) => {
            return '** **** **** **** **' + value.substring(0, 2) + ' ' + value.substring(2, 4)
          }
        },

        // {key: 'actions', label: this.$i18n.t('Actions'), class: 'table-action-buttons'}
      ],
      items: [],
      currentItem: {}
    }
  },
  computed: {},
  mounted() {
    this.getItems()
  },

  methods: {
    dataMeta() {
      return {
        from: this.perPage.value * (this.currentPage.value - 1) + (this.perPage ? 1 : 0),
        to: this.perPage.value * (this.currentPage.value - 1) + this.perPage,
        of: this.items.length,
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    getItems() {
      this.items = []
      this.loaded = false

      this.helperGetItems(`/experts/payout`)
      this.dataMeta()
    },

    formatName(value, key, item) {
      if (item.firstname == null && item.name == null) return item.email

      if (!item.firstname) return item.name

      return item.firstname + ' ' + item.name

    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.p-10-px {
  padding: 10px;
}

.card-congratulations {
  background: #ad8a60;
}

.card {
  color: aliceblue;
}

.award {
  color: white;
}

.circle {
  background-color: #026666;
}

.payout {
  background-color: #026666 !important;
}
</style>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<template>
  <div>

    <b-card
        text-variant="center"
        class="card card-congratulations"
    >
      <!-- images -->
      <b-img
          :src="require('@/assets/images/elements/decore-left.png')"
          class="congratulations-img-left"
      />
      <b-img
          :src="require('@/assets/images/elements/decore-right.png')"
          class="congratulations-img-right"
      />
      <!--/ images -->

      <b-avatar
          variant="primary"
          size="70"
          class="shadow mb-2 circle"
      >
        <feather-icon

            size="28"
            icon="AwardIcon"
            class="award"
        />

      </b-avatar>
      <h1 class="mb-1 mt-50 text-white" v-if="currentItem.credit_amount">
        Congratulations {{ userData.fullName }},
      </h1>
      <h1 class="mb-1 mt-50 text-white" v-else>
        Hey {{ userData.fullName }},
      </h1>
      <b-card-text class="m-auto w-75">
        {{ $t('You have') }} <strong v-if="currentItem.credit_amount">{{ currentItem.credit_amount }}</strong> <strong
          v-else
      > {{ $t('no') }}</strong> {{ $t('Credits available!') }}
      </b-card-text>

    </b-card>


    <div class="py-4">
      <div>
        <b-card class="mx-auto card-payout layout rounded mb-0" :title="$t('Credits Overview')">
          <b-card-text class="text-dark"> {{ $t('You have') }} {{ currentItem.credit_amount }} {{ $t('Credits available!') }}</b-card-text>
          <div class="mt-2">
            <label class="mt-1 mb-2">{{ $t('How many Credits to you want to pay out?') }}</label>
            <b-form-input  :placeholder="$t('Amounts of Credits')" class="mx-auto w-75" v-model="creditAmount"></b-form-input>
          </div>
          <div class="mt-2">
            <b-card-text class="mx-auto text-center text-dark">{{ $t('Please select an Payment Method') }}</b-card-text>
            <b-row class="mt-1 mx-auto">
              <b-col :cols="items.length == 1?'12':'6'" v-for="(item,index) in items" :key="index">
                <b-card @click="paymentId = item.id" :class="{ 'selected-card-background' : paymentId == item.id}"
                        v-model="paymentId" v-if="index < limit_by" class="card-size rounded mx-auto"
                >
                  <div>
                    <b-card-text class="font-weight-bold text-dark">{{ item.name }}</b-card-text>
                  </div>
                  <div>
                    <b-card-text class="text-muted">IBAN</b-card-text>
                  </div>
                  <div class="w-100">
                    <div>
                      <b-card-text class="text-muted">** **** **** **** **{{ item.last_four.substring(0, 2) }} {{ item.last_four.substring(2, 4) }}</b-card-text>
                    </div>
                    <div class="w-100 d-flex justify-content-end">
                      <check-circle-icon class="text-success icon-size" v-if="paymentId == item.id"></check-circle-icon>
                    </div>
                  </div>
                </b-card>
              </b-col>
              <b-col cols="12">
                <b-card-text v-if="items.length > 2"><a href="javascript:void(0)" class="mt-1 ml-2 text-center"
                                                        @click="showMore(default_limit, items.length)"
                >{{ limit_by === 2 ? `* Show more Payment Options (${items.length - 2} more)` : 'Show less' }}</a>*
                </b-card-text>
              </b-col>

            </b-row>
          </div>
          <div class="mt-2">
            <b-form-checkbox
                id="terms-and-condition"
                v-model="agree"
                name="terms_and_condition"
                class="mt-1"
            >{{ $t('I have read the conditional Terms and I agree to it') }}
            </b-form-checkbox>
          </div>
          <div class="mt-2">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                type="submit"
                size="sm"
                @click="payOutCredits"
            >
              {{ $t('Pay out Credits') }}
            </b-button>
          </div>
        </b-card>
      </div>
    </div>
    <open-credit-payout-table></open-credit-payout-table>
  </div>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardText,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BIcon,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BOverlay,
  BPagination,
  BRow,
  BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import moment from 'moment'
import {CheckCircleIcon} from 'vue-feather-icons'
import OpenCreditPayoutTable from "@/views/credits/OpenCreditPayoutTable";

export default {
  components: {
    OpenCreditPayoutTable,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BOverlay,
    BAlert,
    BIcon,
    BLink,
    BCardText,
    BFormCheckbox,
    vSelect,
    CheckCircleIcon,
    BImg,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loaded: false,
      perPage: 10,
      default_limit: 2,
      limit_by: 2,
      paymentId: null,
      agree: false,
      pageOptions: [10, 25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      userData: JSON.parse(localStorage.getItem("userData")),
      tableColumns: [

        {
          key: 'type',
          sortable: true,
          label: this.$i18n.t('Type'),
          formatter: (value) => {
            return this.$i18n.t(value)
          }
        },
        {
          key: 'date',
          sortable: true,
          label: this.$i18n.t('Date'),
        },
        {
          key: 'Title',
          sortable: true,
          label: this.$i18n.t('Title'),
          formatter: (value) => {
            return moment(String(value))
                .format('DD.MM.YYYY HH:mm')
          }
        },

        // {key: 'actions', label: this.$i18n.t('Actions'), class: 'table-action-buttons'}
      ],
      items: [],
      currentItem: {},
      creditAmount: 0
    }
  },
  computed: {},
  mounted() {
    this.getItems()
    this.helperGetItem(`/info/credits`)
    window.addEventListener("userData", (event) => {
      this.userData = JSON.parse(event.detail.storage);
    });
  },
  methods: {
    showMore(default_limit, filters_length) {
      this.limit_by = (this.limit_by === default_limit) ? filters_length : default_limit
    },
    selectPaymentMethod(idx) {
      this.selected = idx
    },
    dataMeta() {
      return {
        from: this.perPage.value * (this.currentPage.value - 1) + (this.perPage ? 1 : 0),
        to: this.perPage.value * (this.currentPage.value - 1) + this.perPage,
        of: this.items.length,
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    getItems() {
      this.items = []
      this.loaded = false

      this.helperGetItems(`/experts/paymentmethods`)
      this.dataMeta()
    },
    deleteItem(id) {
      this.helperDeleteItem(
          `/employees/${id}`,
      )
    },

    formatName(value, key, item) {
      if (item.firstname == null && item.name == null) return item.email

      if (!item.firstname) return item.name

      return item.firstname + ' ' + item.name

    },
    payOutCredits() {
      if (!this.agree) {
        return   this.$bvToast.toast(this.$i18n.t('Accept Conditional Terms'), {
          title: this.$i18n.t('Error'),
          variant: 'danger',
          toaster: 'b-toaster-top-right'
        })
      }
      if (!this.paymentId) {
        return   this.$bvToast.toast(this.$i18n.t('Please select an Payment Method'), {
          title: this.$i18n.t('Error'),
          variant: 'danger',
          toaster: 'b-toaster-top-right'
        })
      }

      this.$http.post('/experts/payout', {
        creditAmount: this.creditAmount,
        paymentId: this.paymentId
      }).then(response => {
        this.helperGetItem(`/experts/credits`)
        this.creditAmount = 0
        this.$router.push({name: 'credits-management'})
        this.$bvToast.toast(this.$i18n.t('Credit paid successfully'), {
          title: this.$i18n.t('Success'),
          variant: 'success',
          toaster: 'b-toaster-top-right'
        })

      })
          .catch(e => {
            this.$bvToast.toast(this.$i18n.t('Credit not paid successfully'), {
              title: this.$i18n.t('Error'),
              variant: 'danger',
              toaster: 'b-toaster-top-right'
            })
          })
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.p-10-px {
  padding: 10px;
}

.card-payout {
  box-shadow: 0px 0px 10px 6px rgb(0 0 0 / 10%) !important;
  max-width: 36rem;
}

.card-size {
  box-shadow: 0px 0px 10px 6px rgb(0 0 0 / 10%) !important;
  max-width: 15rem;
  height: 7rem;
}

.card-size .card-body {
  align-items: flex-start !important;
  padding: 10px 5px 10px 10px !important;
}

.selected-card-background {
  background-color: #e6ffe9 !important;
}

.layout .card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon-size {
  max-width: 20px;
  padding: 2px;
}

.card-congratulations {
  background: #ad8a60;
}

.card {
  color: aliceblue;
}

.award {
  color: white;
}

.circle {
  background-color: #026666;
}

.payout {
  background-color: #026666 !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
